import { DefineComponent } from 'vue';
import { reinitializeVueComponent } from '@/js/pages/helpers/infinite-scroll/reinitializeVueComponent';
import { TrackerProps } from '@/js/components/Tippy/TrackerEmbedTippy/types';
import TrackerEmbedTippy from '@/js/components/Tippy/TrackerEmbedTippy/TrackerEmbedTippy.vue';

type TrackerEmbedTippyComponent = DefineComponent<
    typeof TrackerEmbedTippy['$props'],
    typeof TrackerEmbedTippy['$data'],
    typeof TrackerEmbedTippy['$computed'],
    typeof TrackerEmbedTippy['$methods']
>;

reinitializeVueComponent<TrackerEmbedTippyComponent, TrackerProps>(TrackerEmbedTippy);
